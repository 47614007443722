@import '../../scss-commons/variables';
@import '../../scss-commons/mixins';

.ErrorContainer {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  color: $bg-grad-start;
  font-size: 32px;
  padding: 24px 32px;
  border: 1px dashed currentColor;
  border-top: none;

  @include medium() {
    position: fixed;
  }

  @include small() {
    font-size: 24px;
    padding: 16px 24px;
  }
}
