@import '../../scss-commons/mixins';

.AppContainer {
  background: white;
  box-shadow: 0 0 10px rgba(black, 0.5);
  flex: 0 1 1000px;
  min-height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @include medium() {
    min-height: 100%;
    box-shadow: none;
  }
}
