@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap&subset=latin-ext);
/**
* A set of reasonable defaults for a <button>-like element.
* Mostly meant for elements that show an icon via SVG or icon fonts.
* For buttons with visible text content, prefer the text-button mixin.
**/
/**
* Extends the button mixin to also inherit the font family from the
* parent element.
**/
body {
  margin: 0;
  font: 16px/1.5 'Roboto', sans-serif;
  color: #53666E;
  background: -webkit-gradient(linear, left top, right bottom, from(#EC7B71), to(#F19570)) fixed;
  background: -webkit-linear-gradient(top left, #EC7B71, #F19570) fixed;
  background: linear-gradient(to bottom right, #EC7B71, #F19570) fixed; }
  @media (max-width: 1000px) {
    body {
      background: white; } }

#root {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }

/**
* A set of reasonable defaults for a <button>-like element.
* Mostly meant for elements that show an icon via SVG or icon fonts.
* For buttons with visible text content, prefer the text-button mixin.
**/
/**
* Extends the button mixin to also inherit the font family from the
* parent element.
**/
.AppContainer_AppContainer__3Gsm_ {
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  flex: 0 1 1000px;
  min-height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; }
  @media (max-width: 1000px) {
    .AppContainer_AppContainer__3Gsm_ {
      min-height: 100%;
      box-shadow: none; } }

@-webkit-keyframes LoadingIndicator_spin__3R1kJ {
  from {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn); }
  to {
    -webkit-transform: rotate(-1turn);
            transform: rotate(-1turn); } }

@keyframes LoadingIndicator_spin__3R1kJ {
  from {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn); }
  to {
    -webkit-transform: rotate(-1turn);
            transform: rotate(-1turn); } }

/**
* A set of reasonable defaults for a <button>-like element.
* Mostly meant for elements that show an icon via SVG or icon fonts.
* For buttons with visible text content, prefer the text-button mixin.
**/
/**
* Extends the button mixin to also inherit the font family from the
* parent element.
**/
.LoadingIndicator_LoadingIndicator__3V0ld {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: LoadingIndicator_spin__3R1kJ 1s linear infinite;
          animation: LoadingIndicator_spin__3R1kJ 1s linear infinite;
  font-size: 32px; }
  @media (max-width: 1000px) {
    .LoadingIndicator_LoadingIndicator__3V0ld {
      position: fixed; } }

/**
* A set of reasonable defaults for a <button>-like element.
* Mostly meant for elements that show an icon via SVG or icon fonts.
* For buttons with visible text content, prefer the text-button mixin.
**/
/**
* Extends the button mixin to also inherit the font family from the
* parent element.
**/
.ErrorContainer_ErrorContainer__3DB_p {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  color: #EC7B71;
  font-size: 32px;
  padding: 24px 32px;
  border: 1px dashed currentColor;
  border-top: none; }
  @media (max-width: 1000px) {
    .ErrorContainer_ErrorContainer__3DB_p {
      position: fixed; } }
  @media (max-width: 550px) {
    .ErrorContainer_ErrorContainer__3DB_p {
      font-size: 24px;
      padding: 16px 24px; } }

/**
* A set of reasonable defaults for a <button>-like element.
* Mostly meant for elements that show an icon via SVG or icon fonts.
* For buttons with visible text content, prefer the text-button mixin.
**/
/**
* Extends the button mixin to also inherit the font family from the
* parent element.
**/
.SearchView_SearchForm__3Cq35 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  padding: 32px; }

.SearchView_SearchForm_control__Xd8vM {
  border-bottom: 1px solid currentColor;
  display: flex;
  margin-bottom: 32px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .SearchView_SearchForm_control__Xd8vM div {
    flex: 1 1 100%; }
  .SearchView_SearchForm_control__Xd8vM input[type="search"] {
    background: none;
    border: none;
    text-align: center;
    font: inherit;
    font-weight: bold;
    font-size: 40px;
    padding-left: 32px;
    line-height: 1;
    color: currentColor;
    outline: none;
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
    width: 100%; }
    .SearchView_SearchForm_control__Xd8vM input[type="search"]::-webkit-input-placeholder {
      opacity: 0.5; }
    .SearchView_SearchForm_control__Xd8vM input[type="search"]::-moz-placeholder {
      opacity: 0.5; }
    .SearchView_SearchForm_control__Xd8vM input[type="search"]:-ms-input-placeholder {
      opacity: 0.5; }
    .SearchView_SearchForm_control__Xd8vM input[type="search"]::-ms-input-placeholder {
      opacity: 0.5; }
    .SearchView_SearchForm_control__Xd8vM input[type="search"]::placeholder {
      opacity: 0.5; }
  .SearchView_SearchForm_control__Xd8vM button[type="submit"] {
    border: none;
    background: none;
    color: currentColor;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    padding: 0;
    -webkit-transition: color 100ms linear;
    transition: color 100ms linear;
    /**
  * Removes the additional dotted outline on focused buttons in Firefox
  **/
    font-size: 32px;
    flex: 0 0 32px; }
    .SearchView_SearchForm_control__Xd8vM button[type="submit"]:focus {
      outline: thin dotted #DA9885; }
    .SearchView_SearchForm_control__Xd8vM button[type="submit"]::-moz-focus-inner {
      border: none; }
    .SearchView_SearchForm_control__Xd8vM button[type="submit"]:hover {
      color: black; }
    .SearchView_SearchForm_control__Xd8vM button[type="submit"]:disabled {
      cursor: not-allowed; }

.SearchView_SearchForm_gps__2n8SH {
  border: none;
  background: none;
  color: currentColor;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  padding: 0;
  -webkit-transition: color 100ms linear;
  transition: color 100ms linear;
  /**
  * Removes the additional dotted outline on focused buttons in Firefox
  **/
  font-family: inherit;
  font-size: 20px;
  margin-top: 12px; }
  .SearchView_SearchForm_gps__2n8SH:focus {
    outline: thin dotted #DA9885; }
  .SearchView_SearchForm_gps__2n8SH::-moz-focus-inner {
    border: none; }
  .SearchView_SearchForm_gps__2n8SH u {
    text-decoration: none;
    display: inline-block;
    position: relative; }
    .SearchView_SearchForm_gps__2n8SH u:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 0px;
      border-bottom: 1px dashed currentColor; }
    .SearchView_SearchForm_gps__2n8SH u:hover:after {
      border-bottom-style: solid; }

/**
* A set of reasonable defaults for a <button>-like element.
* Mostly meant for elements that show an icon via SVG or icon fonts.
* For buttons with visible text content, prefer the text-button mixin.
**/
/**
* Extends the button mixin to also inherit the font family from the
* parent element.
**/
.ResultsView_ResultsView__1WBJ0 {
  align-self: stretch;
  flex: 1 0 auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.ResultsView_Header__14Zg0 {
  display: flex;
  align-items: center; }
  .ResultsView_Header__14Zg0 h1 {
    margin: 0 0 0 32px;
    font-size: 40px; }
  .ResultsView_Header__14Zg0 .ResultsView_end__1MCWP {
    margin-left: auto; }
  @media (max-width: 550px) {
    .ResultsView_Header__14Zg0 {
      margin-bottom: 40px; } }

.ResultsView_BackButton__2Uh30 {
  border: none;
  background: none;
  color: currentColor;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  padding: 0;
  -webkit-transition: color 100ms linear;
  transition: color 100ms linear;
  /**
  * Removes the additional dotted outline on focused buttons in Firefox
  **/
  font-size: 32px; }
  .ResultsView_BackButton__2Uh30:focus {
    outline: thin dotted #DA9885; }
  .ResultsView_BackButton__2Uh30::-moz-focus-inner {
    border: none; }
  .ResultsView_BackButton__2Uh30:hover {
    color: black; }

.ResultsView_Now__26F7r time {
  display: block;
  font-size: 40px; }
  @media (max-width: 550px) {
    .ResultsView_Now__26F7r time {
      font-size: 32px; } }

.ResultsView_Now_Condition_Text__2TscT {
  font-size: 32px; }
  @media (max-width: 550px) {
    .ResultsView_Now_Condition_Text__2TscT {
      font-size: 24px; } }

.ResultsView_Now_Body__3snN8 {
  color: #DA9885;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.ResultsView_Now_Condition__F8l7c {
  font-size: 120px;
  white-space: nowrap; }
  .ResultsView_Now_Condition__F8l7c .ResultsView_icon__3CYnk {
    margin: 0 108px 0 52px; }
    @media (max-width: 550px) {
      .ResultsView_Now_Condition__F8l7c .ResultsView_icon__3CYnk {
        margin-right: 24px;
        margin-left: 32px; } }
  @media (max-width: 550px) {
    .ResultsView_Now_Condition__F8l7c {
      font-size: 64px; } }

.ResultsView_TemperaturesTable__1WBot {
  font-size: 20px; }
  .ResultsView_TemperaturesTable__1WBot th {
    text-align: left;
    font-weight: normal;
    padding-right: 32px; }
  .ResultsView_TemperaturesTable__1WBot td {
    text-align: right; }

.ResultsView_Days__1Rxud {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 550px) {
    .ResultsView_Days__1Rxud {
      display: block;
      margin-top: 40px; } }

/**
* A set of reasonable defaults for a <button>-like element.
* Mostly meant for elements that show an icon via SVG or icon fonts.
* For buttons with visible text content, prefer the text-button mixin.
**/
/**
* Extends the button mixin to also inherit the font family from the
* parent element.
**/
.DayBlock_DayBlock__1F3So {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .DayBlock_DayBlock__1F3So time {
    font-size: 13px; }
  @media (max-width: 550px) {
    .DayBlock_DayBlock__1F3So {
      flex-direction: row;
      padding: 16px 0;
      border-bottom: 1px dashed currentColor; }
      .DayBlock_DayBlock__1F3So:last-child {
        border-bottom: none;
        padding-bottom: 0; }
      .DayBlock_DayBlock__1F3So time {
        flex: 1 1;
        font-size: 20px; } }

.DayBlock_icon__1mHCv {
  font-size: 40px;
  line-height: 1;
  margin: 10px 0; }
  @media (max-width: 550px) {
    .DayBlock_icon__1mHCv {
      margin: 0 32px; } }

/**
* A set of reasonable defaults for a <button>-like element.
* Mostly meant for elements that show an icon via SVG or icon fonts.
* For buttons with visible text content, prefer the text-button mixin.
**/
/**
* Extends the button mixin to also inherit the font family from the
* parent element.
**/
.TempModeToggle_TempModeToggle__3O34g {
  border: none;
  background: none;
  color: currentColor;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  padding: 0;
  -webkit-transition: color 100ms linear;
  transition: color 100ms linear;
  /**
  * Removes the additional dotted outline on focused buttons in Firefox
  **/
  font-family: inherit;
  border: 1px solid currentColor;
  overflow: hidden;
  border-radius: 16px;
  width: 84px;
  height: 32px;
  display: flex;
  align-items: stretch;
  padding: 2px 1px; }
  .TempModeToggle_TempModeToggle__3O34g:focus {
    outline: thin dotted #DA9885; }
  .TempModeToggle_TempModeToggle__3O34g::-moz-focus-inner {
    border: none; }
  .TempModeToggle_TempModeToggle__3O34g:hover .TempModeToggle_Handle__3VTcC {
    box-shadow: 0 0 10px; }

.TempModeToggle_TempModeToggleActive__hehfn .TempModeToggle_Inner__tncWo {
  -webkit-transform: translate3d(-70%, 0, 0);
          transform: translate3d(-70%, 0, 0); }

.TempModeToggle_Inner__tncWo {
  display: flex;
  align-items: center;
  width: 100%;
  -webkit-transform: translate3d(-2px, 0, 0);
          transform: translate3d(-2px, 0, 0);
  -webkit-transition: -webkit-transform 100ms ease-in-out;
  transition: -webkit-transform 100ms ease-in-out;
  transition: transform 100ms ease-in-out;
  transition: transform 100ms ease-in-out, -webkit-transform 100ms ease-in-out; }
  .TempModeToggle_Inner__tncWo span {
    flex: 0 0 70%;
    text-align: center; }
  .TempModeToggle_Inner__tncWo .TempModeToggle_Handle__3VTcC {
    flex-basis: calc(30% - 2px);
    align-self: stretch;
    border: 1px solid currentColor;
    border-radius: 13px;
    margin: 0 1px;
    box-shadow: 0 0 0;
    -webkit-transition: box-shadow 100ms linear;
    transition: box-shadow 100ms linear; }

