@import '../../scss-commons/variables';
@import '../../scss-commons/mixins';

.ResultsView {
  align-self: stretch;
  flex: 1 0 auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Header {
  display: flex;
  align-items: center;

  h1 {
    margin: 0 0 0 32px;
    font-size: 40px;
  }

  .end {
    margin-left: auto;
  }

  @include small() {
    margin-bottom: 40px;
  }
}

.BackButton {
  @include button();

  font-size: 32px;

  &:hover {
    color: black;
  }
}

.Now {
  time {
    display: block;
    font-size: 40px;

    @include small() {
      font-size: 32px;
    }
  }
}

.Now_Condition_Text {
  font-size: 32px;

  @include small() {
    font-size: 24px;
  }
}

.Now_Body {
  color: $highlight-color;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Now_Condition {
  font-size: 120px;
  white-space: nowrap;

  .icon {
    margin: 0 108px 0 52px;

    @include small() {
      margin-right: 24px;
      margin-left: 32px;
    }
  }

  @include small() {
    font-size: 64px;
  }
}

.TemperaturesTable {
  font-size: 20px;

  th {
    text-align: left;
    font-weight: normal;
    padding-right: 32px;
  }

  td {
    text-align: right;
  }
}

.Days {
  display: flex;
  justify-content: space-between;

  @include small() {
    display: block;
    margin-top: 40px;
  }
}
