@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap&subset=latin-ext');
@import '~material-design-icons/iconfont/material-icons.css';
@import '~weather-icons/css/weather-icons.css';

@import './scss-commons/variables';
@import './scss-commons/mixins';

body {
  margin: 0;
  font: 16px/1.5 'Roboto', sans-serif;
  color: $text-color;
  background: linear-gradient(to bottom right, $bg-grad-start, $bg-grad-end) fixed;

  @include medium() {
    background: white;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} 
