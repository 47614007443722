@import '../../scss-commons/variables';
@import '../../scss-commons/mixins';

.SearchForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  padding: 32px;
}

.SearchForm_control {
  border-bottom: 1px solid currentColor;
  display: flex;
  margin-bottom: 32px;
  appearance: none;

  div {
    flex: 1 1 100%;
  }

  input[type="search"] {
    background: none;
    border: none;
    text-align: center;
    font: inherit;
    font-weight: bold;
    font-size: 40px;
    padding-left: 32px;
    line-height: 1;
    color: currentColor;
    outline: none;
    appearance: textfield;
    width: 100%;

    &::placeholder {
      opacity: 0.5;
    }
  }

  button[type="submit"] {
    @include button();

    font-size: 32px;
    flex: 0 0 32px;

    &:hover {
      color: black;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.SearchForm_gps {
  @include text-button();

  font-size: 20px;
  margin-top: 12px;

  u {
    text-decoration: none;
    display: inline-block;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 0px;
      border-bottom: 1px dashed currentColor;
    }

    &:hover {
      &:after {
        border-bottom-style: solid;
      }
    }
  }
}
